import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';
import classNames from 'classnames';

import ChallengeHeader from 'components/challenge/ChallengeHeader';
import ChallengeFooter from 'components/challenge/ChallengeFooter';
import OrgPickerContainer from 'components/challenge/OrgPickerContainer';
import MulesoftAccountConnector from './MulesoftAccountConnector';
import SpecialityOrgManager from '../units/SpecialityOrgManager';

export default class Assessment extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    msaSigned: PropTypes.bool.isRequired,
    points: PropTypes.number.isRequired,
    hideOrgPicker: PropTypes.bool,
    isSubmitting: PropTypes.bool.isRequired,
    isSubmitDisabled: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    unitType: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    renderHelperText: PropTypes.func.isRequired,
    requirements: PropTypes.array,
    selectedOrg: PropTypes.object,
    tpHelpLink: PropTypes.string.isRequired,
    evaluationContext: PropTypes.object.isRequired,
    mulesoftProps: PropTypes.object,
    previewMode: PropTypes.bool,
    unitUid: PropTypes.string,
    jitEnabled: PropTypes.bool,
  };

  get orgPickerProps() {
    return {
      apiName: this.props.apiName,
      orgs: this.props.evaluationContext.authorized_accounts,
      jitEnabled: this.props.jitEnabled
    };
  }

  get customTemplateRequired() {
    return this.props.evaluationContext.metadata?.authorization_requirements?.custom_template_required;
  }

  isAuthedWithAccountType(accountType) {
    return (
      this.props.authenticated &&
      this.props.evaluationContext.authorized_account_type === accountType
    );
  }

  renderOrgPicker(cssStyle) {
    return (
      <div className={cssStyle}>
        <OrgPickerContainer
          {...this.orgPickerProps}
        />
      </div>
    )
  }

  renderSpecialityOrgManager(cssStyle) {
    return (
      <>
        <SpecialityOrgManager
          unitUid={this.props.unitUid}
          authenticated={this.props.authenticated}
          authorizedAccount={this.props.evaluationContext.authorized_accounts.find(account => account.template_match)}
          productLabel={this.props.evaluationContext.metadata?.authorization_requirements?.product_label}
          previewMode={this.props.previewMode}
        >
        </SpecialityOrgManager>
        {this.props.previewMode && this.renderOrgPicker(cssStyle)}
      </>
    );
  }

  renderChallengeAccountManager(accountType, unitType) {
    const cssStyle = unitType === 'step' ? 'slds-m-top--medium' : 'th-challenge__requirements-footer';

    if (accountType === 'salesforce_org' && this.customTemplateRequired) {
      if (unitType === 'unit') {
        return (
          <>
            {this.renderSpecialityOrgManager(cssStyle)}
          </>
        )
      } else {
        return (
          <div className="th-challenge__requirements slds-m-top_large">
            {this.renderSpecialityOrgManager(cssStyle)}
          </div>
        )
      }
    }

    if (!this.isAuthedWithAccountType(accountType)) {
      return "";
    }

    if (accountType === 'salesforce_org') {
      return this.renderOrgPicker(cssStyle);
    } else if (accountType === 'mulesoft_anypoint_account') {
      return (
        <div className={cssStyle}>
          <MulesoftAccountConnector
              {...this.props.mulesoftProps}
              {...this.props.evaluationContext}
          />
        </div>
      );
    }
  }

  renderUnitChallenge = () => (
    <div>
      <h3 className="slds-text-title_caps slds-m-bottom_medium slds-m-top_large">
        {t('challenge.assessment.challenge')}
      </h3>

      <div className="th-challenge__requirements slds-m-top_large">
        {(this.props.title || this.props.description) && <div className="th-challenge__requirements-content">
          {this.props.title && <div className="slds-text-heading_small tds-text_bold slds-m-bottom_large">
            {this.props.title}
          </div>}

          {this.props.description && <div
            className="th-challenge__description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />}

          {this.renderRequirements()}
        </div>}

        {this.renderChallengeAccountManager('salesforce_org', 'unit')}

        {this.renderChallengeAccountManager('mulesoft_anypoint_account', 'unit')}
      </div>
    </div>
  );

  renderRequirements = () =>
    this.props.requirements.length > 0 && (
      <ul className="slds-m-top_large">
        {this.props.requirements.map((r, idx) => (
          <li
            key={`req_${idx}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: r }}
          />
        ))}
      </ul>
    );

  renderUnitChallengeHeading = () => (
    <h3 className="slds-text-title_caps slds-m-bottom_medium">
      {t('challenge.assessment.get_ready')}
    </h3>
  );

  renderHandsOnChallengeHelpText = (challengeType, evaluationContext) => {
    if (evaluationContext === 'mulesoft_anypoint_account') {
      return (
        <p className="slds-m-bottom--small">
          {t('challenge.assessment.mulesoft.explanation', {
            type: t(`challenge.assessment.unitType.${challengeType}`),
          })}
        </p>
      );
    }
    if (evaluationContext === 'salesforce_org' && this.customTemplateRequired) {
      return (<p className="slds-m-bottom--small">
        {t('challenge.assessment.explanation_generic', {
          productLabel: this.props.evaluationContext.metadata?.authorization_requirements?.product_label,
        })}
      </p>
      );
    }
    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: t('challenge.assessment.explanation', {
            type: t(`challenge.assessment.unitType.${challengeType}`),
          }),
        }}
      />
    );
  };

  render() {
    const challengeTypeText = (unitType) => (
      unitType === 'unit'
        ? 'unit'
        : 'project'
    );

    return (
      <section className="th-challenge slds-m-top--xx-large">
        <form onSubmit={this.props.onSubmit}>
          <ChallengeHeader
            points={this.props.points}
            heading={t(`challenge.assessment.${this.props.unitType}.title`)}
          />

          <div
            className={classNames('slds-m-around_large', {
              'slds-m-around_none': this.props.hideOrgPicker,
            })}
          >
            {this.props.unitType === 'unit' &&
              this.renderUnitChallengeHeading()}

            {
              !this.props.hideOrgPicker &&
              this.renderHandsOnChallengeHelpText(
                challengeTypeText(this.props.unitType),
                this.props.evaluationContext.authorized_account_type
              )
            }

            {this.props.unitType === 'unit' && this.renderUnitChallenge()}

            {this.props.unitType === "step" && this.renderChallengeAccountManager('salesforce_org', 'step')}

            {this.props.unitType === "step" && this.renderChallengeAccountManager('mulesoft_anypoint_account', 'step')}
          </div>

          <ChallengeFooter
            {...this.props}
            selectedOrg={this.props.selectedOrg}
            hideOrgPicker={this.props.hideOrgPicker}
            type="assessment"
            unitType={this.props.unitType}
          />
        </form>
      </section>
    );
  }
}
