import { t } from 'lib/I18n';

const DEFAULT_CATEGORIES = {
  id: 'categorySettings',
  label: t('views.trailmaker.settings.menu.category'),
  items: [
    { id: 'roles', label: t('views.trailmaker.settings.menu.roles') },
    { id: 'levels', label: t('views.trailmaker.settings.menu.levels') },
    { id: 'products', label: t('views.trailmaker.settings.menu.products') },
  ]
};

const OTHER_SETTINGS = {
  id: 'mySettings',
  label: t('views.trailmaker.settings.menu.other'),
  items: [
    { id: 'branding', label: t('views.trailmaker.settings.menu.branding') },
    { id: 'navigation', label: t('views.trailmaker.settings.menu.navigation') },
  ]
};

const OTHER_SETTINGS_PUBLIC = {
  id: 'mySettings',
  label: t('views.trailmaker.settings.menu.other'),
  items: []
};

const NAV_ITEMS = [
  DEFAULT_CATEGORIES,
  OTHER_SETTINGS,
];

const NAV_ITEMS_PUBLIC = [
  DEFAULT_CATEGORIES
];

const VerticalNavigationItems = ({
  publicTrailmaker,
  showBadgeRatings,
  showContentExport
}) => {
  let items;

  if (publicTrailmaker) {
    // TODO: Switch these to use structuredClone after we upgrade to node 17+.
    // Most browsers already support it, but js unit tests will fail unless we update node.
    items = JSON.parse(JSON.stringify(NAV_ITEMS_PUBLIC));

    // Add Skills, Industries & Objective Category for Public Trailhead
    items[0].items.push({ id: 'skills', label: t('views.trailmaker.settings.menu.skills') });
    items[0].items.push({ id: 'industries', label: t('views.trailmaker.settings.menu.industries') });
    items[0].items.push({ id: 'objective', label: t('views.trailmaker.settings.menu.objective') });

    if (showBadgeRatings || showContentExport) {
      items.push(JSON.parse(JSON.stringify(OTHER_SETTINGS_PUBLIC)));
    }
  } else {
    items = JSON.parse(JSON.stringify(NAV_ITEMS));
  }

  if (showContentExport) {
    items[1].items.push({ id: 'export', label: t('views.trailmaker.settings.menu.export') })
  }

  if (showBadgeRatings) {
    items[1].items.push({ id: 'ratings', label: t('views.trailmaker.settings.menu.ratings') })
  }

  return items;
};

export default VerticalNavigationItems;
