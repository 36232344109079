import React from "react";
import PropTypes from "prop-types";

import { Transition } from "react-transition-group";
import { Portal } from "react-portal";

import { t, I18n } from "lib/I18n";
import UnitReward from "./UnitReward";
import RewardDetails from "./RewardDetails";
import SuperbadgeReward from "./SuperbadgeReward";
import NextRewardButton from "./NextRewardButton";
import NextChallengeButton from "./NextChallengeButton";
import RewardConfetti from "./RewardConfetti";
import WebComponent from "../WebComponent";
import GtmTracker, { transformPayload } from "../../lib/tracking/trackGtm";

const isType =
  (expectedType) =>
  ({ type }) =>
    expectedType === type;
const isTypeEventBadge = isType("Th::EventBadge");
const isTypeSuperBadge = isType("Th::Superbadge");

const parents = {
  unit: "module",
  step: "project"
};

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

function isBadge({ type }) {
  return type === "Th::ModuleBadge" || type === "Th::Superbadge";
}

export default class RewardsModalContent extends React.Component {
  static propTypes = {
    rewards: PropTypes.array.isRequired,
    nextChallenge: PropTypes.object.isRequired,
    showRecommendations: PropTypes.bool,
    badgeRatings: PropTypes.object.isRequired,
    colorScheme: PropTypes.string.isRequired,
    sharing: PropTypes.object.isRequired,
    moduleApiName: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,
    trialCta: PropTypes.object,
    namespaceId: PropTypes.string
  };

  state = {
    rewardIdx: 0,
    showNewRecommendations: true,
    hasNewRecommendations: false
  };

  trackInteraction = (e, eventAction) => {
    const payload = {
      eventLabel: `${e.detail.contentType}.${e.detail.apiName}`,
      eventCategory: "favorites",
      eventAction
    };

    new GtmTracker().track(transformPayload(payload));
  };

  canShowNewRecommendations() {
    const { nextChallenge } = this.props;

    return this.state.showNewRecommendations && !nextChallenge.next_challenge && !nextChallenge.next_parent;
  }

  canShowRateBadge() {
    const { badgeRatings } = this.props;
    return this.props.rewards.some(isBadge) && badgeRatings.show_badge_ratings === true;
  }

  filteredRewards = () => {
    if (this.props.rewards.some((r) => r.type === "Th::ModuleBadge")) {
      return this.props.rewards.filter((r) => r.type !== "Th::Unit" && r.type !== "Th::Step");
    }
    return this.props.rewards;
  };

  sharingProps = (reward) => (reward.type === "Th::ModuleBadge" ? this.props.sharing : null);

  nextReward = () => {
    this.setState({ rewardIdx: this.state.rewardIdx + 1 });
  };

  renderReward = (reward) => {
    switch (reward.type) {
      case "Th::Unit":
        return <UnitReward reward={reward} />;
      case "Th::Step":
        return <UnitReward reward={reward} />;
      case "Th::Superbadge":
        return <SuperbadgeReward reward={reward} next={this.props.nextChallenge} sharing={this.props.sharing} />;
      default: {
        const title = reward.type === "Th::EventBadge" ? t("reward.event.heading") : t("reward.module.heading");

        return (
          <RewardDetails
            title={title}
            subtitle={t("reward.module.subheading")}
            badgeName={reward.title}
            iconUrl={reward.badge_url}
            assistiveText={{ iconLabel: t("reward.module.complete") }}
            sharing={this.sharingProps(reward)}
          />
        );
      }
    }
  };

  renderSlide = (reward, idx) => {
    const show = idx === this.state.rewardIdx;

    return (
      <Transition key={`reward_${idx}`} in={show} timeout={500} mountOnEnter unmountOnExit exit={false}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            {this.renderReward(reward)}
          </div>
        )}
      </Transition>
    );
  };

  renderModalBody = () => <div className="slds-p-around_medium">{this.filteredRewards().map(this.renderSlide)}</div>;

  renderModalFooter = () => {
    if (this.fromTrail() && !this.props.nextChallenge.next_challenge) {
      return this.renderTrailButton();
    }

    if (this.canShowNewRecommendations()) {
      return null;
    }

    let footerContent = null;

    if (this.state.rewardIdx < this.filteredRewards().length - 1) {
      footerContent = <NextRewardButton nextReward={this.nextReward} />;
    } else {
      const unitType = this.props.rewards[this.state.rewardIdx].type === "Th::Step" ? "step" : "unit";
      footerContent = <NextChallengeButton unitType={unitType} nextChallenge={this.props.nextChallenge} />;
    }

    return <div className="slds-border_top slds-p-top_medium slds-text-align_center">{footerContent}</div>;
  };

  renderConfetti = () => {
    if (this.props.rewards.some(isBadge)) {
      return (
        <Portal>
          <RewardConfetti colorScheme={this.props.colorScheme} />
        </Portal>
      );
    }
    return "";
  };

  fromTrail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const trailId = urlParams.get("trail_id");
    return trailId;
  };

  handleRecommendationsStateChange = (e) => {
    const { state } = e.detail;
    if (state === "error" || state === "empty") {
      this.setState({ showNewRecommendations: false });
    }
    if (state === "loaded" && !this.state.hasNewRecommendations) {
      this.setState({ hasNewRecommendations: true });
    }
  };

  renderNewRecommendations = () => {
    if (!this.state.showNewRecommendations || this.fromTrail()) {
      return null;
    }

    if (this.props.nextChallenge.next_challenge || this.props.nextChallenge.next_parent) {
      return null;
    }

    const attrs = {
      uuid: this.props.moduleUid
    };

    const events = {
      statechange: this.handleRecommendationsStateChange
    };

    const containerClassName = this.state.hasNewRecommendations ? "slds-p-around_large tds-bg_sand" : "";

    return (
      <div className={containerClassName}>
        {this.state.hasNewRecommendations && (
          <div className="slds-p-bottom_large tds-text_bold slds-text-align_center">
            {t("challenge.recommendation.title")}
          </div>
        )}
        <WebComponent tagName="th-recommendations-updated" attrs={attrs} events={events} />
      </div>
    );
  };

  renderRateBadge = () => {
    if (!this.canShowRateBadge()) {
      return null;
    }
    const { badgeRatings } = this.props;

    const attrs = {
      apiName: badgeRatings.api_name,
      trackable: badgeRatings.trackable
    };

    return (
      <div className="slds-p-around_small">
        <WebComponent tagName="th-badge-rating" attrs={attrs} />
      </div>
    );
  };

  trialCtaEvent = (eventName) => {
    return new CustomEvent("trailhead_track", {
      bubbles: true,
      composed: true,
      detail: {
        eventName: eventName,
        payload: {
          cta_type: "trial",
          unit_name: `${this.props.trialCta.unit_name}`,
          page_location: "completion_bottom",
          cta_href: this.props.trialCta.url,
          lang: I18n.locale,
          label: this.props.trialCta.btn_text
        }
      }
    });
  };

  trackTrialCta = () => {
    document.dispatchEvent(this.trialCtaEvent("cta_clicked"));
  };

  trackTrialCtaViewed = () => {
    document.dispatchEvent(this.trialCtaEvent("cta_viewed"));
  };

  renderTrialCta = () => {
    // do not display if within an incomplete module or trail
    if (this.props.nextChallenge.next_challenge || this.props.nextChallenge.next_parent) {
      return null;
    }

    if (this.props.trialCta) {
      this.trackTrialCtaViewed();

      return (
        <tds-button-link
          href={this.props.trialCta.url}
          id="trial-promo-btn__reward-modal"
          target="_blank"
          size="medium"
          variant="brand"
          style={{ width: "100%", marginTop: "1rem" }}
          onClick={this.trackTrialCta}
        >
          {this.props.trialCta.btn_text}
        </tds-button-link>
      );
    }
  };

  renderTrailButton = () => {
    if (this.fromTrail()) {
      return (
        <div
          style={{
            borderTop: "#c9c7c5 solid 1px",
            margin: "0 -16px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <tds-button-link
            href={`/content/${this.props.namespaceId}/trails/${this.fromTrail()}`}
            size="medium"
            variant="brand"
            style={{ marginTop: "1rem" }}
          >
            <span>{t("reward.button.return_to_trail")}</span>
          </tds-button-link>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.renderModalBody()}
        {this.renderRateBadge()}
        {this.renderNewRecommendations()}
        {this.renderTrialCta()}
        {this.renderModalFooter()}
        {this.renderConfetti()}
      </div>
    );
  }
}
